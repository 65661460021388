/**
 * Formats currency
 * @param value The value to format. Should be number overwise empty value will be rendered.
 * @param currency Currency name
 * @param notation Notation for the number
 * @returns Formatted string
 */
export const formatCurrency = (
  value: unknown,
  currency?: string,
  notation: "compact" | "standard" = "compact"
): string => {
  if (typeof value !== "number") {
    return "-";
  }
  // Fix: -0 should be just 0
  const valueToProcess = value || 0;
  return currency
    ? new Intl.NumberFormat(navigator.language, {
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: valueToProcess === 0 ? 0 : 2,
        notation,
        style: "currency"
      }).format(valueToProcess)
    : new Intl.NumberFormat(navigator.language, {
        currency: "USD",
        maximumFractionDigits: 2,
        minimumFractionDigits: valueToProcess === 0 ? 0 : 2,
        notation,
        style: "currency"
      })
        .format(valueToProcess)
        .replace("$", "");
};

/**
 * Converts the app locale to Intl compatible locale
 * @param appLocale App locale
 * @returns Intl compatible locale
 */
export const convertToIntlLocale = (appLocale: string): string => {
  switch (appLocale) {
    case "LAS":
      return "es-419";
    case "SC":
      return "zh-CN";
    case "ZH":
      return "zh-TW";
    default:
      return appLocale;
  }
};
