import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { DateTime } from "luxon";

import styles from "./LatestStatements.module.scss";
import { ReactComponent as DownloadIcon } from "../../../../icons/download.svg";
import { ReactComponent as FileLinesIcon } from "../../../../icons/file-lines.svg";

import PageHeader from "../../../../components/shared/PageHeader";
import Button, { ButtonType } from "../../../../components/shared/UiButton";
import { DocumentType } from "../../../../types/documents";
import { convertToIntlLocale } from "../../../../utils/format";
import { DATE_PERIOD_FORMAT } from "../../constants/date";
import { LatestStatementsProps } from "./LatestStatements.types";

/**
 * Renders latest statements widget
 * @param props React component properties
 * @returns JSX element
 */
const LatestStatements = (props: LatestStatementsProps): JSX.Element => {
  const { i18n, t } = useTranslation("translation");

  const locale = convertToIntlLocale(i18n.language);

  const statements = useMemo(() => props.statements.slice(0, props.count), [
    props.count,
    props.statements
  ]);

  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.header}>
        <PageHeader className={styles.h2} variant="h2">
          {t("home.latestStatements")}
        </PageHeader>
        <Link
          className={styles.link}
          to={`/documents?selectedType=${DocumentType.ROYALTY_STATEMENT}`}
        >
          {t("home.showAll")}
        </Link>
      </div>
      <hr className={styles.hr} />
      <div className={styles.documents}>
        {statements.length === 0 && (
          <div className={styles.noData}>
            <FileLinesIcon className={styles.docIcon} />
            {t("home.noStatementsAvailable")}
          </div>
        )}
        {statements.map(statement => {
          const formattedStartDate = DateTime.fromISO(statement.startDate)
            .setLocale(locale)
            .toFormat(DATE_PERIOD_FORMAT);
          const formattedEndDate = DateTime.fromISO(statement.endDate)
            .setLocale(locale)
            .toFormat(DATE_PERIOD_FORMAT);
          return (
            <div
              className={styles.document}
              key={`${statement.startDate}-${statement.endDate}`}
            >
              <FileLinesIcon className={styles.docIcon} />
              <div className={styles.docTitle}>
                {formattedStartDate} - {formattedEndDate}
              </div>
              <Button
                hideDropdownIcon
                menuItems={[
                  {
                    onClick: () =>
                      props.onDownloadClick(statement, ["csv", "pdf"]),
                    text: t("home.allFormats")
                  },
                  {
                    onClick: () => props.onDownloadClick(statement, ["pdf"]),
                    text: "PDF"
                  },
                  {
                    onClick: () => props.onDownloadClick(statement, ["csv"]),
                    text: "CSV"
                  }
                ]}
                variant={ButtonType.Text}
              >
                <DownloadIcon className={styles.downloadIcon} />
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LatestStatements;
