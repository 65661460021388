import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Switch, useHistory } from "react-router-dom";
import { SecureRoute } from "@okta/okta-react";
import cn from "classnames";

import styles from "./Analytics.module.scss";

import Condition from "../../components/shared/Condition";
import IncomeGroups from "../../components/shared/IncomeGroups";
import LedgerBlock from "../../components/shared/LedgerBlock";
import Loader from "../../components/shared/Loading";
import NoDataAnalytics from "../../components/shared/NoDataAnalytics";
import TerritoriesBar from "../../components/shared/TerritoriesBar";
import TopSongsChart from "../../components/shared/TopSongsChart";
import TopSourcesChart from "../../components/shared/TopSourcesChart";
import { useActions } from "../../hooks/useActions";
import {
  incomesLoadingSelector,
  songsLoadingSelector,
  songsTopSelector,
  sourcesLoadingSelector,
  sourcesTopSelector,
  territoriesLoadingSelector,
  top4IncomeTypesSelector,
  top4TerritoriesSelector
} from "../../store/analytics/selectors";
import {
  payeeCodeSelector,
  profileSelector
} from "../../store/appStatic/selectors";
import {
  ledgerSelector,
  loadingLedgersSelector
} from "../../store/dealsStore/selectors";
import AnalyticsNavLink from "./NavLink";
import TopIncomeGroups from "./TopIncomeGroups";
import TopSongs from "./TopSongs";
import TopSources from "./TopSources";
import TopTerritories from "./TopTerritories";

let currentCode = "";
let startDate = "";
let endDate = "";
const Analytics = () => {
  const history = useHistory();
  const payeeCode = useSelector(payeeCodeSelector);
  const profile = useSelector(profileSelector);
  const ledger = useSelector(ledgerSelector);
  const isInner = history.location.pathname !== "/analytics";
  const ledgersLoading = useSelector(loadingLedgersSelector);

  const top4IncomeTypes = useSelector(top4IncomeTypesSelector);
  const songsTop = useSelector(songsTopSelector);
  const sourcesTop = useSelector(sourcesTopSelector);
  const top4Territories = useSelector(top4TerritoriesSelector);

  const [isInitiated, setIsInitiated] = useState(false);

  const isIncomesLoading = useSelector(incomesLoadingSelector);
  const isSongsLoading = useSelector(songsLoadingSelector);
  const isSourcesLoading = useSelector(sourcesLoadingSelector);
  const isTerritoriesLoading = useSelector(territoriesLoadingSelector);

  const ledgerVisible = false;

  const noAnalyticsData =
    !top4IncomeTypes?.length ||
    !songsTop?.topWorks?.length ||
    !sourcesTop?.topSources?.length ||
    !top4Territories?.length;

  const {
    getTopSongsAction,
    getTopSourcesAction,
    GetLedgersAction,
    getTopTerritoriesAction,
    getTopIncomeGroupsAction
  } = useActions();

  useEffect(() => {
    if (
      ledger?.data?.startDate !== startDate ||
      ledger?.data?.endDate !== endDate ||
      payeeCode !== currentCode
    ) {
      startDate = ledger?.data?.startDate;
      endDate = ledger?.data?.endDate;
      currentCode = payeeCode;
      getTopSongsAction({ payeeCode, data: ledger.data });
      getTopSourcesAction({ payeeCode, data: ledger.data });
      getTopTerritoriesAction({ payeeCode, data: ledger.data });
      getTopIncomeGroupsAction({ payeeCode, data: ledger.data });
    }
    setIsInitiated(true);
  }, [ledger?.data]);

  useEffect(() => {
    GetLedgersAction(payeeCode, history);
  }, [payeeCode]);

  if (
    !profile ||
    (ledgersLoading && !isInner) ||
    isIncomesLoading ||
    isSongsLoading ||
    isSourcesLoading ||
    isTerritoriesLoading ||
    !isInitiated
  ) {
    return <Loader />;
  }

  return (
    <div className={"container"}>
      <LedgerBlock hideLedgersInfoBlock={!ledgerVisible || isInner} />

      {isInner && <AnalyticsNavLink />}
      <Condition
        condition={noAnalyticsData}
        Truthy={<NoDataAnalytics />}
        Falsy={
          <div
            className={cn(styles.analytics, {
              [styles.hideChard]: isInner
            })}
          >
            <TopSongsChart />
            <IncomeGroups />
            <TopSourcesChart />
            <TerritoriesBar />
          </div>
        }
      />
      <Switch>
        <SecureRoute path="/analytics/topSongs" component={TopSongs} exact />
        <SecureRoute
          path="/analytics/incomeGroups"
          component={TopIncomeGroups}
          exact
        />
        <SecureRoute
          path={"/analytics/topSources"}
          component={TopSources}
          exact
        />
        <SecureRoute
          path={"/analytics/territoriesBar"}
          component={TopTerritories}
          exact
        />
      </Switch>
    </div>
  );
};

export default Analytics;
