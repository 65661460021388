import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  overrides: {
    MuiPopover: {
      paper: {
        backgroundColor: "black",
        border: "1px solid white",
        borderRadius: "6px",
        color: "white",
        minWidth: "145px",
        padding: "20px 0"
      }
    },
    MuiTooltip: {
      arrow: {
        "&:before": { backgroundColor: "#383838", border: "1px solid white" }
      },
      tooltip: {
        backgroundColor: "#383838",
        border: "1px solid white",
        borderRadius: 0,
        color: "white",
        fontFamily: "inherit",
        fontSize: "13px",
        lineHeight: "16px",
        maxWidth: "200px",
        padding: "10px"
      }
    }
  },
  props: {
    MuiPopover: {
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      transformOrigin: { horizontal: "right", vertical: "top" }
    },
    MuiTooltip: { arrow: true, enterDelay: 500, placement: "top" }
  }
});
